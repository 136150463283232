import { defineMessages, useIntl } from "react-intl";

import { DeprecatedStyledSelectInput } from "common/form/inputs/select";
import RequiredAsterisk from "common/core/form/required-asterisk";
import { TitleLookupErrors } from "common/mortgage/transactions/edit/title_agency_service";
import type { TitleAgencyOption } from "common/mortgage/transactions/edit/title_collaborator/title_agency_autocomplete_service";
import { FieldErrorMessage } from "common/core/form/error";

import type { useCollaboratorSearchingType } from ".";
import TitleAgencyNameOrIdInput from "./title_agency_name_or_id_input";
import Styles from "./index.module.scss";
import { ControlLabel } from "..";

type CollaboratorEmailResultProps = {
  interaction: ReturnType<typeof useCollaboratorSearchingType>;
};

const MESSAGES = defineMessages({
  userFound: {
    id: "a6ae7cdb-a252-454c-aa93-6db9cebc7ef6",
    defaultMessage: "Great! This user is associated with the following business:",
  },
  domainNameMatchFound: {
    id: "27f4be31-00b5-4d7d-9af9-5189ef3443d6",
    defaultMessage: "Great! Here is the associated title organization:",
  },
  userNotFound: {
    id: "0fcdc337-8f53-4a09-9de9-05b8ec1f14b7",
    defaultMessage: "This looks like a new user. Fill in the remaining information.",
  },
  userNotCollaborator: {
    id: "eef5c3ee-0a09-4ad3-9a08-411828560ef1",
    defaultMessage:
      "This email address is associated with a non-collaborator account, and cannot be added to this transaction. Please enter another email address.",
  },
  emailDomainNotValid: {
    id: "89998187-68d9-4282-bf07-d9a72a42e1e3",
    defaultMessage: "The provided email domain is not valid.",
  },
  orgNameOrId: {
    id: "a6ae7cdb-a252-454c-aa93-6db9cebc7ef6",
    defaultMessage: "Organization name / ID ",
  },
  firstName: {
    id: "44025887-7da9-4159-991b-4a84a0680f7a",
    defaultMessage: "First name",
  },
  lastName: {
    id: "6c4b8cf9-7ed7-4e05-9eb1-07e539414296",
    defaultMessage: "Last name",
  },
});

const valueRenderer = (option: TitleAgencyOption) => (
  <>
    <span className={Styles.valueRender}>{option.label}</span>
    {option.value && <span className={Styles.organizationId}> ({option.value})</span>}
  </>
);

function SuccessResult({
  interaction,
  interaction: { titleAgentLookup },
}: CollaboratorEmailResultProps) {
  const intl = useIntl();

  const { error, titleAgencyId, titleAgencyName, domainMatched } = titleAgentLookup!;

  if (!error) {
    return (
      <>
        <ControlLabel>
          {intl.formatMessage(domainMatched ? MESSAGES.domainNameMatchFound : MESSAGES.userFound)}
        </ControlLabel>
        <div className={Styles.collaboratorOrgNameDetails}>
          <DeprecatedStyledSelectInput
            key="title-agency-name-or-id"
            name="titleAgencyNameOrId"
            data-automation-id="title-agency-name-or-id"
            placeholderAsLabel
            disabled
            valueRenderer={valueRenderer}
            onChange={() => {}}
            items={[
              {
                value: titleAgencyId,
                label: titleAgencyName,
              },
            ]}
            value={titleAgencyId}
            placeholder={intl.formatMessage(MESSAGES.orgNameOrId)}
          />
        </div>
      </>
    );
  } else if (error === TitleLookupErrors.NOT_FOUND) {
    return (
      <>
        <ControlLabel>
          {intl.formatMessage(MESSAGES.userNotFound)}
          <RequiredAsterisk />
        </ControlLabel>

        <TitleAgencyNameOrIdInput interaction={interaction} />
      </>
    );
  }

  return null;
}

type ErrorProps = {
  error?: TitleLookupErrors | null;
};

function ErrorResult({ error }: ErrorProps) {
  const intl = useIntl();
  if (error === TitleLookupErrors.NOT_TITLE_AGENT) {
    return (
      <FieldErrorMessage
        inputName="collaboratorEmail"
        message={intl.formatMessage(MESSAGES.userNotCollaborator)}
      />
    );
  } else if (error === TitleLookupErrors.EMAIL_DOMAIN_NOT_VALID) {
    return (
      <FieldErrorMessage
        inputName="collaboratorEmail"
        message={intl.formatMessage(MESSAGES.emailDomainNotValid)}
      />
    );
  }

  return null;
}
const Divider = () => <div className={Styles.divider} />;

export function isValidTitleAgentEmailInput(error?: TitleLookupErrors | null) {
  return !error || error === TitleLookupErrors.NOT_FOUND;
}

function CollaboratorEmailResult({
  interaction,
  interaction: { titleAgentLookup },
}: CollaboratorEmailResultProps) {
  if (!titleAgentLookup) {
    return null;
  }

  const { error } = titleAgentLookup;

  if (isValidTitleAgentEmailInput(error)) {
    return (
      <>
        <Divider />
        <SuccessResult interaction={interaction} />
      </>
    );
  }

  return <ErrorResult error={error} />;
}

export default CollaboratorEmailResult;
