import { type ReactNode, useState, useEffect, useRef } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import RequiredAsterisk from "common/core/form/required-asterisk";
import { Pill } from "common/core/pill_tabs";
import { Card } from "common/core/card";
import { DeprecatedStyledSelectInput } from "common/form/inputs/select";
import { scrollAndFocusInput, scrollElementIntoView } from "util/html";

import type { TitleUnderwriter_viewer_eligibleTitleUnderwriters as EligibleTitleUnderwriter } from "../collaborator/title_underwriters.query.graphql";
import Styles from "./index.module.scss";
import { useNewSetupPageAnalytics, ControlLabel } from "..";

type TitleUnderwriterOption = "yes" | "no";

export type InteractionProps = {
  titleAgencyId?: string | null;
};

type TitleUnderwriterSelection = {
  value: string | null;
  label: string | null;
};

type TitleUnderwriterOptionPillsProps = {
  option: TitleUnderwriterOption | null;
  handleOnPillClick: (option: TitleUnderwriterOption) => void;
};

type TitleUnderwriterCardProps = {
  /** lenderOrgId indicates whether the component is render from the lender portal or title portal. */
  lenderOrgId?: string | null;
  /** The interaction containing states that determine what to render */
  interaction: ReturnType<typeof useTitleUnderWriterOption>;
  eligibleTitleUnderwriters: EligibleTitleUnderwriter[];
  collaborative?: boolean;
};

const MESSAGES = defineMessages({
  collaborative: {
    id: "60cb1540-1ce1-401e-8a39-e571ad30f890",
    defaultMessage:
      "We have verified that one or more title underwriters meet this closing's requirements. If you know the underwriter, select it below. If you do not, your collaborator will be prompted to select one.",
  },
  nonCollaborative: {
    id: "bde582e1-8d2a-481a-8d21-0b822bbfd270",
    defaultMessage:
      "We display underwriters that work with this title business, work in the property's state, and whose notary requirements are serviceable on Proof. Ineligible underwriters are not shown.",
  },
  lender: {
    id: "a96da687-834e-47d9-a923-400d5d64cd14",
    defaultMessage:
      "{collaborative, select, true {Do you know the title underwriter?} other {Who is the title underwriter?}}",
  },
  collaborator: {
    id: "3b225a86-3724-4f11-899f-16d79cccd7cf",
    defaultMessage: "Are you issuing a title policy on this transaction?",
  },
  collaboratorQuestion: {
    id: "04cc04c4-56da-4672-acfe-31b3eca259ca",
    defaultMessage: "Who is the title underwriter?",
  },
  selectUnderwriter: {
    id: "f6846369-b6c9-49c9-8ca8-6bced5fe1a53",
    defaultMessage: "Select a title underwriter",
  },
  noUnderwritersFound: {
    id: "831c5b1f-024a-4e1c-a9da-228c381c1422",
    defaultMessage: "No eligible underwriters found.",
  },
});

const allowedTitleUnderwriterOptionType: TitleUnderwriterOption[] = ["yes", "no"];

const TitleUnderwriterOptionLabels: Record<TitleUnderwriterOption, ReactNode> = Object.freeze({
  yes: <FormattedMessage id="99384378-7494-474b-ad11-0acdc8bcb130" defaultMessage="Yes" />,
  no: <FormattedMessage id="af2179d0-ca37-4a34-a34f-325e36fbcb86" defaultMessage="No" />,
});

const rowRenderer = (option: TitleUnderwriterSelection) =>
  option.label && <div className={Styles.optionRender}>{option.label}</div>;

const valueRenderer = (option: TitleUnderwriterSelection) =>
  option.label && <div className={Styles.valueRender}>{option.label}</div>;

const TitleUnderwriterOptionPills = ({
  option,
  handleOnPillClick,
}: TitleUnderwriterOptionPillsProps) => (
  <div className={Styles.container}>
    {allowedTitleUnderwriterOptionType.map((type) => (
      <Pill
        key={`pill-${type}`}
        selected={option === type}
        onClick={() => handleOnPillClick(type)}
        data-automation-id={`underwriter-option-${type}`}
      >
        {TitleUnderwriterOptionLabels[type]}
      </Pill>
    ))}
  </div>
);

export const useTitleUnderWriterOption = (props: InteractionProps) => {
  const { titleAgencyId } = props;
  const [option, setOption] = useState<TitleUnderwriterOption | null>(null);
  const [titleUnderwriter, setTitleUnderwriter] = useState<EligibleTitleUnderwriter | null>(null);

  const clear = () => {
    setOption(null);
    setTitleUnderwriter(null);
  };

  useEffect(() => {
    clear();
  }, [titleAgencyId]);

  return {
    option,
    setOption,
    titleUnderwriter,
    setTitleUnderwriter,
    clear,
  };
};

export default function TitleUnderwriterCard({
  lenderOrgId = null,
  interaction: { option, setOption, titleUnderwriter, setTitleUnderwriter },
  eligibleTitleUnderwriters,
  collaborative = false,
}: TitleUnderwriterCardProps) {
  const intl = useIntl();
  const headerRef = useRef<HTMLDivElement>(null);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const { track } = useNewSetupPageAnalytics();

  const nonCollaborativeLender = Boolean(lenderOrgId && !collaborative);
  const titleUnderwriterSelected = option === "yes";
  const viewerMessage = lenderOrgId
    ? intl.formatMessage(MESSAGES.lender, { collaborative })
    : intl.formatMessage(MESSAGES.collaborator);

  useEffect(() => {
    if (selectContainerRef.current) {
      scrollAndFocusInput(selectContainerRef.current.querySelector("input"));
    } else {
      scrollElementIntoView(headerRef.current);
    }
  }, []);

  const handleOptionChange = (option?: TitleUnderwriterSelection) => {
    const underwriter = eligibleTitleUnderwriters.find(({ id }) => id === option?.value);
    setTitleUnderwriter(underwriter || null);
    track("underwriter");
  };

  const handleOnPillClick = (type: TitleUnderwriterOption) => {
    setTitleUnderwriter(null);
    setOption(type);
    track("underwriter-question", {
      type,
    });
  };

  return (
    <Card automationId="title-underwriter-card">
      <ControlLabel ref={headerRef}>
        {viewerMessage}
        <RequiredAsterisk />
      </ControlLabel>
      {!lenderOrgId && (
        <TitleUnderwriterOptionPills option={option} handleOnPillClick={handleOnPillClick} />
      )}

      {lenderOrgId && collaborative && (
        <>
          <div className={Styles.body}>{intl.formatMessage(MESSAGES.collaborative)}</div>
          <TitleUnderwriterOptionPills option={option} handleOnPillClick={handleOnPillClick} />
        </>
      )}

      {Boolean(eligibleTitleUnderwriters.length) && (
        <>
          {(nonCollaborativeLender || (!lenderOrgId && titleUnderwriterSelected)) && (
            <>
              {!lenderOrgId && (
                <div className={Styles.collaboratorTextContent}>
                  <ControlLabel>
                    {intl.formatMessage(MESSAGES.collaboratorQuestion)}
                    <RequiredAsterisk />
                  </ControlLabel>
                </div>
              )}

              <div className={Styles.body}>{intl.formatMessage(MESSAGES.nonCollaborative)}</div>
            </>
          )}

          {(titleUnderwriterSelected || nonCollaborativeLender) && (
            <div className={Styles.titleUnderwriterOption} ref={selectContainerRef}>
              <DeprecatedStyledSelectInput
                key="title-underwriter-option"
                data-automation-id="title-underwriter-option"
                name="titleUnderwriterOption"
                placeholderAsLabel
                clearable
                searchable
                onChange={() => {}}
                items={eligibleTitleUnderwriters.map((underwriter) => {
                  return {
                    value: underwriter.id,
                    label: underwriter.name,
                  };
                })}
                optionRenderer={rowRenderer}
                valueRenderer={valueRenderer}
                onOptionChange={handleOptionChange}
                value={titleUnderwriter?.id ?? undefined}
                placeholder={intl.formatMessage(MESSAGES.selectUnderwriter)}
                openMenuOnFocus
              />
            </div>
          )}
        </>
      )}
    </Card>
  );
}
