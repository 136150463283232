import "./index.scss";
import "common/form/form.scss";

import { useEffect } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import compose from "util/compose";
import { isURL } from "util/string";
import { useMutation } from "util/graphql";
import SaveButton from "common/core/save_button";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedFormRowHelpTooltip } from "common/form/form_row_help_tooltip";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedFirstNameField, DeprecatedLastNameField } from "common/form/fields/name_fields";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import AddressSubForm, {
  validationRules as addressValidationRules,
} from "common/form/sub_forms/address";
import { composeValidators, getFormValues } from "util/form";
import { validatePresence } from "validators/form";

import SetupRealEstOrganizationMutation from "./setup_real_est_organization_mutation.graphql";

type FormValues = {
  companyName: string;
  logo: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  firstName: string;
  lastName: string;
};
type Props = {
  admin: boolean;
  onChangesSaved: () => void;
  organization: {
    id: string;
    name: string | null;
    logoUrl: string | null;
    address: null | {
      line1: string | null;
      line2: string | null;
      city: string | null;
      state: string | null;
      postal: string | null;
      country: string | null;
    };
  };
  viewer: {
    user: null | {
      firstName: string | null;
      lastName: string | null;
    };
  };
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props & { dispatch: () => void };

const MESSAGES = defineMessages({
  orgNamePlaceholder: {
    id: "47c83cc0-e6af-4e0a-8ede-85cde8ce8567",
    defaultMessage: "Account Name",
  },
});

function validate(values: FormValues, props: Props) {
  return composeValidators(
    addressValidationRules(values, props),
    validatePresence({ field: "companyName", label: "Account Name" }),
    validatePresence({ field: "firstName", label: "First Name" }),
    validatePresence({ field: "lastName", label: "Last Name" }),
  )(values);
}

function SetupForm(props: InnerProps) {
  const {
    organization,
    handleSubmit,
    dispatch, // eslint-disable-line @typescript-eslint/no-unused-vars
    change, // eslint-disable-line @typescript-eslint/no-unused-vars
    untouch, // eslint-disable-line @typescript-eslint/no-unused-vars
    form,
    invalid,
    admin,
    ...restProps
  } = props;
  const intl = useIntl();

  useEffect(() => {
    const { name, logoUrl, address } = organization;
    const { firstName, lastName } = props.viewer.user!;
    props.initialize({
      companyName: name || "",
      logo: logoUrl || "",
      line1: address?.line1 || "",
      line2: address?.line2 || "",
      city: address?.city || "",
      state: address?.state || "",
      postal: address?.postal || "",
      country: address?.country || "US",
      firstName: firstName || "",
      lastName: lastName || "",
    });
  }, []);

  const updateOrganizationMutateFn = useMutation(SetupRealEstOrganizationMutation);
  const handleSave = (values: FormValues) => {
    const s3Key = !isURL(values.logo) ? values.logo : null;
    return updateOrganizationMutateFn({
      variables: {
        input: {
          id: organization.id,
          name: values.companyName,
          firstName: values.firstName,
          lastName: values.lastName,
          logoS3Key: s3Key,
          address: {
            line1: values.line1,
            line2: values.line2,
            city: values.city,
            state: values.state,
            postal: values.postal,
            country: values.country,
          },
        },
      },
    }).then(() => props.onChangesSaved());
  };

  if (!admin) {
    return null;
  }

  return (
    <form className="Form" onSubmit={handleSubmit(handleSave)}>
      <FormGroup fields={["companyName"]} className="setup-detail-company" disableFormRowStyle>
        <DeprecatedTextField
          id="companyName"
          name="companyName"
          data-automation-id="company-name-field"
          placeholder={intl.formatMessage(MESSAGES.orgNamePlaceholder)}
          placeholderAsLabel
          useStyledInput
        />

        <DeprecatedFormRowHelpTooltip tooltipPlacement="top" iconOutside>
          <FormattedMessage
            id="2d269390-2ed6-453c-9731-0db221c9e9bd"
            defaultMessage="Account Name will appear in the transaction email that is sent to the signer."
          />
        </DeprecatedFormRowHelpTooltip>

        <FormGroupErrors fields={["companyName"]} />
      </FormGroup>

      <FormGroup
        fields={["firstName", "lastName"]}
        className="SetupForm-lead--name-field"
        disableFormRowStyle
      >
        <DeprecatedMultipartRow>
          <DeprecatedFirstNameField
            id="firstName"
            name="firstName"
            data-automation-id="first-name-field"
            placeholderAsLabel
            useStyledInput
          />
          <DeprecatedLastNameField
            id="lastName"
            name="lastName"
            data-automation-id="last-name-field"
            placeholderAsLabel
            useStyledInput
          />
        </DeprecatedMultipartRow>

        <FormGroupErrors fields={["firstName"]} />

        <FormGroupErrors fields={["lastName"]} />
      </FormGroup>

      <AddressSubForm formName={form} useStyledInputs />

      <FormGroup>
        <SaveButton
          className="SetupForm-lead--save-button"
          title={
            <FormattedMessage
              id="8f9f8c38-d359-4e95-a33c-f2c89e9299d9"
              defaultMessage="Save & Continue"
            />
          }
          disabled={invalid}
          {...restProps}
        />
      </FormGroup>
    </form>
  );
}

export default compose(
  reduxForm({ form: "smbSettingsDetails", validate }),
  getFormValues("smbSettingsDetails"),
)(SetupForm);
