import { useParams, Navigate } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import { QueryWithLoading } from "util/graphql/query";
import { transactionEditRouteV3, transactionDetailsRoute } from "util/routes";
import { useActiveOrganization } from "common/account/active_organization";
import { TransactionErrorRedirect } from "common/transaction_creation/v3/form";

import TransactionEditQuery from "./transaction_edit_query.graphql";

function EditTransactionContainerWithData() {
  const [activeOrganizationId] = useActiveOrganization();
  const { transactionId } = useParams();

  return (
    <QueryWithLoading
      query={TransactionEditQuery}
      variables={{
        transactionId,
        organizationId: activeOrganizationId,
      }}
    >
      {({ data, loading, error }) => {
        // QueryWithLoading only loads for the first load and so we need this to catch subsequent loads with a different transaction id
        if (loading) {
          return <LoadingIndicator />;
        }
        if (!data?.transaction) {
          return <TransactionErrorRedirect error={error} />;
        }

        const transaction = data.transaction;

        if (!transaction.editable) {
          return <Navigate replace to={transactionDetailsRoute(transaction.id)} />;
        }

        return <Navigate replace to={transactionEditRouteV3(transactionId)} />;
      }}
    </QueryWithLoading>
  );
}

export default EditTransactionContainerWithData;
