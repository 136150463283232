import { lazy, type ComponentProps, type ReactElement, Suspense, type ReactNode } from "react";
import {
  Outlet,
  Routes,
  Route,
  Navigate,
  useMatch,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { mortgageDashboardDeserializer } from "common/mortgage/transactions/dashboard/filter";
import APP from "constants/applications";
import ShortcutCheatsheet from "common/shortcut_cheatsheet";
import TargetAuthLinkHandler from "common/target_auth_link_handler";
import App from "common/app";
import SessionExpiredModal from "common/authorization/session_expired_modal";
import { composeViewerWrappers, createViewerManager, useViewer } from "util/viewer_wrapper";
import { useIAnav, useTxnDetailsRedesign } from "util/feature_detection";
import { useIsAuthenticated, HardLoginRedirect } from "common/authentication";
import { MfaSetupBlocker } from "common/authentication/mfa_setup_blocker";
import { LenderRedirectChecker } from "common/authorization";
import { NotaryQueueProvider } from "common/notary/queue";
import TermsOfServiceBlocking from "common/modals/terms_of_service/blocking";
import Logger from "common/logger";
import SupportChat from "common/support/chat";
import LenderAppFrame from "lender_portal/app_frame";
import ResetPassword from "common/account/login/reset_password";
import ExportModal from "common/transactions/transaction_v2/export";
import OrganizationRoleContext from "common/organization/organization_role_context";
import VerifyEnabled from "common/organization/authorization/verify_enabled";
import { OrganizationFeatureGatingSpecialization } from "common/feature_gating/specialization";
import VerifyEmail from "common/account/verify_email";
import ConfiguredRedirect from "common/configured";
import InvalidPaymentBlocker from "common/configured/invalid_payment";
import Activation from "lender_portal/account/activation";
import BusinessSetup from "common/organization/setup";
import { makeUserManagementRoutes } from "common/organization/member_management/routing";
import BusinessEmployeeTransactions from "common/employees/transactions";
import TransactionDetails from "common/transactions/details";
import { SummaryContainer } from "common/details/summary";
import IdentityDetails from "common/details/identity";
import PointsOfContactDetails from "common/details/recipients/points_of_contact";
import { DeprecatedNotaryDetailsContainer } from "common/details/meeting/notary_details/deprecated/deprecated_index";
import { DeprecatedWitness } from "common/details/meeting/witness_details/deprecated_index";
import { DeprecatedVideoContainer } from "common/details/meeting/videos/deprecated/deprecated_index";
import TransactionDetailsBundle from "common/details/bundle";
import { History } from "common/details/history";
import TransactionEmployeeDetails from "common/transactions/employee_details";
import SetupTransactionV2 from "lender_portal/transactions/setup/v2";
import InstructionDetails from "common/details/instructions";
import { OrganizationTemplates } from "common/organization_templates";
import OrganizationTemplatePrepare from "common/organization_templates/prepare";
import OrganizationTemplateEdit from "common/organization_templates/edit";
import OrganizationTemplateTools from "common/organization_templates/tools";
import LenderTransactions from "lender_portal/transactions";
import EditTransactionContainer from "lender_portal/transactions/edit/container";
import { useMatchManyFirstPathParts } from "util/location";
import { NavigateWithSearchPreservation } from "util/navigation";
import { ActivityLogs } from "common/transactions/activity_logs";
import Settings from "common/settingsv2";
import NewTransaction from "common/transactions/new";
import TransactionEditContainer from "common/transactions/transaction_v2";
import Eligibility from "lender_portal/eligibility";
import LoadingIndicator from "common/core/loading_indicator";
import { OnlineHeartBeatUnavailable } from "common/notary/online_heartbeat";
import { SigningContextWrapper, useMeetingIdParam } from "common/signing_context";
import ProofFrame from "common/proof_frame";
import { TRANSACTION_PATH, EASYLINK_DASHBOARD_PATH, TEMPLATES_DASHBOARD_PATH } from "util/routes";
import {
  type OrganizationPermissions,
  PermissionRedirect as BasePermissionRedirect,
} from "common/core/current_user_role";
import Meeting from "common/mortgage/meeting";
import AudioVideoSettings from "common/meeting/audio_video_settings";
import { Feature, EasylinkTypes } from "graphql_globals";
import { makeSetActiveOrgAndRedirectRoute } from "common/account/active_organization/routing";
import { EasylinkManager } from "common/dashboard/easy_link";
import { EasylinkCreateForm } from "common/dashboard/easy_link/create";
import { EasylinkEditForm } from "common/dashboard/easy_link/edit";
import { RecipientColorProvider } from "common/pdf/recipient_colors/context";
import { RecipientsContainer } from "common/details/recipients";
import { MeetingContainer } from "common/details/meeting";
import ErrorBoundary from "common/error_boundary";
import NotaryMfaBlocker from "common/notary/notary_mfa_blocker";
import MeetingSimulator from "common/meeting_simulator";

import ViewerQuery, { type LenderRouterViewer_viewer as Viewer } from "./viewer_query.graphql";

const ClosingAgenda = lazy(() => import("lender_portal/closing_agenda"));
const RealEstateNotaryMeeting = lazy(() => import("common/mortgage/notary_meeting"));
const TransactionCreationForm = lazy(() => import("common/transaction_creation/v3/form"));

const Frame = (props: ComponentProps<typeof LenderAppFrame>) => {
  return useIAnav() ? (
    <ProofFrame portal="lender">{props.children}</ProofFrame>
  ) : (
    <LenderAppFrame {...props} />
  );
};

const ENTRY = APP.LENDER;
const MOBILE_ROUTES = new Set(["reset_password", "verify-account", "meeting", "meeting/*"]);
const ViewerManager = createViewerManager<Viewer>(ViewerQuery, []);
const DefaultWrapperWithFrame = composeViewerWrappers<Viewer>(
  TermsOfServiceBlocking,
  LenderRedirectChecker,
  VerifyEnabled,
  OrganizationRoleContext,
  RecipientColorProvider,
  OrganizationFeatureGatingSpecialization,
  Frame,
  SupportChat,
  Logger,
  MfaSetupBlocker,
);
const DefaultWrapperWithoutFrame = composeViewerWrappers<Viewer>(
  TermsOfServiceBlocking,
  LenderRedirectChecker,
  VerifyEnabled,
  OrganizationRoleContext,
  RecipientColorProvider,
  OrganizationFeatureGatingSpecialization,
  SupportChat,
  Logger,
  MfaSetupBlocker,
);
const DefaultWrapperUnauth = composeViewerWrappers<Viewer>(SupportChat);

const MeetingWrapper = composeViewerWrappers<Viewer>(
  TermsOfServiceBlocking,
  LenderRedirectChecker,
  VerifyEnabled,
  OrganizationRoleContext,
  RecipientColorProvider,
  OrganizationFeatureGatingSpecialization,
  Logger,
  MfaSetupBlocker,
);

function DefaultWrapper({
  children,
  withoutFrame,
}: {
  children?: ReactElement;
  withoutFrame?: boolean;
}) {
  return withoutFrame ? (
    <DefaultWrapperWithoutFrame>{children || <Outlet />}</DefaultWrapperWithoutFrame>
  ) : (
    <NotaryQueueProvider>
      <DefaultWrapperWithFrame>{children || <Outlet />}</DefaultWrapperWithFrame>
    </NotaryQueueProvider>
  );
}

/* Use as Route element where possible so that parent element stays the same between routes, ensuring single page navigation and preventing unneccessary refresh. Additionally, the <Suspense/> below will keep the nav/sidebar mounted and only put up a loading indicator for the main content when lazy loading. */
function RouteWrapper({
  children,
  withoutFrame,
}: {
  children?: ReactElement;
  withoutFrame?: boolean;
}) {
  return (
    <DefaultWrapper withoutFrame={withoutFrame}>
      <Suspense fallback={<LoadingIndicator />}>{children || <Outlet />}</Suspense>
    </DefaultWrapper>
  );
}

function PermissionRedirect({
  permissions,
  children,
}: {
  permissions: OrganizationPermissions[];
  children?: ReactNode;
}) {
  return (
    <BasePermissionRedirect redirectRoute={<Navigate to="/" replace />} permissions={permissions}>
      {children}
    </BasePermissionRedirect>
  );
}

function WithMfaBlock({ children }: { children?: ReactNode }) {
  return <NotaryMfaBlocker>{children || <Outlet />}</NotaryMfaBlocker>;
}

function TransactionDetailsRoutes({ isTxnDetailsRedesign }: { isTxnDetailsRedesign: boolean }) {
  return (
    <TransactionDetails withActivityLog withNotes className="LenderTransactionDetails">
      {(props) => (
        <Routes>
          <Route path="summary" element={<SummaryContainer {...props} entry={ENTRY} />} />
          <Route
            path="contacts"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../user" replace />
              ) : (
                <PointsOfContactDetails {...props} />
              )
            }
          />
          <Route path="user" element={<RecipientsContainer {...props} />} />
          {isTxnDetailsRedesign && (
            <Route path="meeting" element={<MeetingContainer {...props} />} />
          )}
          <Route path="identity" element={<IdentityDetails {...props} />} />
          <Route
            path="notary"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../meeting" />
              ) : (
                <DeprecatedNotaryDetailsContainer {...props} />
              )
            }
          />
          <Route
            path="witness"
            element={
              isTxnDetailsRedesign ? <Navigate to="../meeting" /> : <DeprecatedWitness {...props} />
            }
          />
          <Route path="document" element={<TransactionDetailsBundle {...props} />} />
          <Route
            path="video"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../meeting" />
              ) : (
                <DeprecatedVideoContainer {...props} />
              )
            }
          />
          <Route path="history" element={<History {...props} />} />
          <Route path="notes" element={<InstructionDetails {...props} />} />
          <Route path="activity" element={<ActivityLogs />} />
          <Route path="*" element={<Navigate replace to="summary" />} />
        </Routes>
      )}
    </TransactionDetails>
  );
}

const UNAUTH_ROUTES = (
  <Route element={<DefaultWrapperUnauth />}>
    <Route path="/reset_password/:token" element={<ResetPassword />} />

    <Route path="*" element={<HardLoginRedirect />} />

    <Route path="/eligibility" element={<Eligibility />} />
  </Route>
);

const authRoutes = (isIHNOrganization: boolean, transactionDetailsRedesignFlag: boolean) => {
  return (
    <>
      {/* needed to prevent redirecting to default route in AUTH_ROUTES after signup */}
      <Route path="/signup/*" element={<LoadingIndicator />} />

      <Route
        path="/setup"
        element={
          <RouteWrapper withoutFrame>
            <PermissionRedirect permissions={["editOrganizationDetails"]}>
              <BusinessSetup />
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        path="/settings/*"
        element={
          <RouteWrapper>
            <PermissionRedirect permissions={["viewOrganizationDetails"]}>
              <Settings />
            </PermissionRedirect>
          </RouteWrapper>
        }
      />

      <Route
        element={
          <RouteWrapper>
            <ConfiguredRedirect>
              <InvalidPaymentBlocker />
            </ConfiguredRedirect>
          </RouteWrapper>
        }
      >
        <Route
          path="/employee-transactions/:organizationId/:memberUserId"
          element={
            <PermissionRedirect
              permissions={["viewOrganizationDetails", "viewOrganizationTransactions"]}
            >
              <BusinessEmployeeTransactions />
            </PermissionRedirect>
          }
        />

        {makeUserManagementRoutes({
          wrapper: <PermissionRedirect permissions={["viewTeamDetails"]} />,
        })}

        {/* Remove with ia-nav as part of REAL-8360 */}
        <Route
          path="/templates"
          element={<PermissionRedirect permissions={["manageLenderTemplates"]} />}
        >
          <Route index element={<OrganizationTemplates />} />
          <Route path=":templateId/new" element={<OrganizationTemplateEdit isMortgage />} />
          <Route path=":templateId/edit" element={<OrganizationTemplateEdit isMortgage />} />
          <Route path=":templateId/tools" element={<OrganizationTemplateTools />} />
          <Route path=":globalID" element={<OrganizationTemplatePrepare />} />
        </Route>

        <Route
          path="/transaction"
          element={
            <WithMfaBlock>
              <PermissionRedirect permissions={["viewOrganizationTransactions"]} />
            </WithMfaBlock>
          }
        >
          <Route index element={<Navigate replace to={TRANSACTION_PATH} />} />
          <Route path="new" element={<Navigate to="/transaction/setup" />} />
          <Route path="create" element={<NewTransaction />} />
          <Route path="esign/:transactionId" element={<TransactionEditContainer />} />
          <Route path="proof/:transactionId" element={<TransactionEditContainer />} />
          <Route path="identify/:transactionId" element={<TransactionEditContainer />} />
          <Route path="setup">
            <Route index element={<SetupTransactionV2 />} />
            <Route path="v2" element={<SetupTransactionV2 />} />
          </Route>
          <Route path="edit/:transactionId">
            <Route index element={<EditTransactionContainer />} />
            {/* Do not delete - email links depend on this */}
            <Route path="details" element={<Navigate to=".." replace />} />
          </Route>
          {/* Can remove with notary-meeting-consolidation removal */}
          <Route path="closing-agenda" element={<ClosingAgenda />}>
            <Route path=":filter" element={null} />
          </Route>
          <Route path=":transactionId" element={<TransactionCreationForm />} />
        </Route>
        {/* end /transaction */}
        <Route
          path="/"
          element={
            <WithMfaBlock>
              <PermissionRedirect permissions={["viewOrganizationTransactions"]} />
            </WithMfaBlock>
          }
        >
          <Route index element={<NavigateWithSearchPreservation to={TRANSACTION_PATH} />} />
          <Route path={TRANSACTION_PATH} element={<LenderTransactions />}>
            <Route
              path="export"
              element={<ExportModal deserializer={mortgageDashboardDeserializer} />}
            />
            <Route
              path="employee/:orgMembershipId"
              element={
                <PermissionRedirect
                  permissions={["viewOrganizationDetails", "viewOrganizationTransactions"]}
                >
                  <TransactionEmployeeDetails />
                </PermissionRedirect>
              }
            />
            {!transactionDetailsRedesignFlag && (
              // Old transaction details MODAL
              <Route
                path=":transactionID/*"
                element={
                  <TransactionDetailsRoutes isTxnDetailsRedesign={transactionDetailsRedesignFlag} />
                }
              />
            )}
          </Route>
          {/* end /transaction/records */}
          <Route path={EASYLINK_DASHBOARD_PATH}>
            <Route
              element={
                <PermissionRedirect permissions={["viewEasyLinks"]}>
                  {/* BIZ-6166: Remove the need to have <LenderTransactions /> as parent */}
                  <LenderTransactions />
                </PermissionRedirect>
              }
            >
              <Route
                index
                element={
                  <EasylinkManager
                    allowedEasylinkTypes={[
                      EasylinkTypes.IDENTIFY,
                      EasylinkTypes.SIGNER_UPLOAD,
                      EasylinkTypes.VERIFY,
                    ]}
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path={TEMPLATES_DASHBOARD_PATH}
            element={
              <PermissionRedirect permissions={["manageLenderTemplates"]}>
                <LenderTransactions />
              </PermissionRedirect>
            }
          >
            <Route index element={<OrganizationTemplates />} />
            <Route path=":templateId/new" element={<OrganizationTemplateEdit isMortgage />} />
            <Route path=":templateId/edit" element={<OrganizationTemplateEdit isMortgage />} />
            <Route path=":templateId/tools" element={<OrganizationTemplateTools />} />
            <Route path=":globalID" element={<OrganizationTemplatePrepare />} />
          </Route>
          <Route
            path="/easylinks/new"
            element={
              <PermissionRedirect permissions={["createEasyLinks"]}>
                <EasylinkCreateForm />
              </PermissionRedirect>
            }
          />
          <Route
            path="/easylinks/edit/:easylinkId"
            element={
              <PermissionRedirect permissions={["updateEasyLinks"]}>
                <EasylinkEditForm />
              </PermissionRedirect>
            }
          />
        </Route>
        {transactionDetailsRedesignFlag && (
          // New transaction details PAGE
          <Route
            path={`${TRANSACTION_PATH}/:transactionID/*`}
            element={
              <PermissionRedirect permissions={["viewOrganizationTransactions"]}>
                <TransactionDetailsRoutes isTxnDetailsRedesign={transactionDetailsRedesignFlag} />
              </PermissionRedirect>
            }
          />
        )}
        {isIHNOrganization && (
          <>
            <Route
              path="/meetings/*"
              element={
                <PermissionRedirect permissions={["viewMeetings"]}>
                  <WithMfaBlock>
                    <ClosingAgenda />
                  </WithMfaBlock>
                </PermissionRedirect>
              }
            />
            <Route
              path="/simulated-meeting/:meetingId"
              element={
                <RouteWrapper withoutFrame>
                  <PermissionRedirect permissions={["viewMeetings"]}>
                    <MeetingSimulator />
                  </PermissionRedirect>
                </RouteWrapper>
              }
            />
          </>
        )}
      </Route>
      {/* end InvalidPaymentBlocker/ConfiguredRedirect grouping */}

      {isIHNOrganization && (
        <Route
          path="/meeting/:meetingId"
          element={
            <PermissionRedirect permissions={["viewMeetings"]}>
              <MeetingWrapper>
                <AudioVideoSettings>
                  <Meeting />
                </AudioVideoSettings>
              </MeetingWrapper>
            </PermissionRedirect>
          }
        />
      )}

      {isIHNOrganization && (
        <Route
          path="/notary-meeting/:meetingId"
          element={
            <RouteWrapper withoutFrame>
              <PermissionRedirect permissions={["viewMeetings"]}>
                <OnlineHeartBeatUnavailable>
                  <SigningContextWrapper useSiginingContextId={useMeetingIdParam}>
                    <RealEstateNotaryMeeting />
                  </SigningContextWrapper>
                </OnlineHeartBeatUnavailable>
              </PermissionRedirect>
            </RouteWrapper>
          }
        />
      )}

      {makeSetActiveOrgAndRedirectRoute({
        path: "/set-active-org/:orgId",
        wrapper: <RouteWrapper />,
        redirect: <Navigate to="/" replace />,
      })}

      <Route path="*" element={<NavigateWithSearchPreservation to={TRANSACTION_PATH} />} />
    </>
  );
};

function LenderRouter() {
  const isAuthenticated = useIsAuthenticated();
  const isMobileSupportedRoute = useMatchManyFirstPathParts(MOBILE_ROUTES);
  const { viewer } = useViewer<Viewer>();
  const isIHNOrganization = Boolean(
    viewer.user?.organization?.featureList.some((feat) => feat === Feature.ORGANIZATION_NOTARIES),
  );
  const isTxnDetailsRedesign = useTxnDetailsRedesign("lender");

  if (useMatch("/shortcut-cheatsheet")) {
    return <ShortcutCheatsheet />;
  }

  return (
    <TargetAuthLinkHandler>
      <App isAuthenticated={isAuthenticated} mobileSupported={isMobileSupportedRoute}>
        <Routes>
          <Route path="/session_expired" element={<SessionExpiredModal />} />

          <Route path="/verify-account" element={<VerifyEmail />} />

          <Route path="/activate/:token" element={<Activation entry={ENTRY} />} />
          {isAuthenticated ? authRoutes(isIHNOrganization, isTxnDetailsRedesign) : UNAUTH_ROUTES}
        </Routes>
      </App>
    </TargetAuthLinkHandler>
  );
}

const Root = () => (
  <ErrorBoundary>
    <ViewerManager>
      <LenderRouter />
    </ViewerManager>
  </ErrorBoundary>
);

const router = createBrowserRouter([{ path: "*", element: <Root /> }]);

export default () => {
  return <RouterProvider router={router} />;
};

export const __LenderRouter = Root;
