import { useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { DeprecatedStyledSelectInput } from "common/form/inputs/select";
import Button from "common/core/button";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import type { TitleAgencyOption } from "common/mortgage/transactions/edit/title_collaborator/title_agency_autocomplete_service";
import { scrollAndFocusInput } from "util/html";

import type { useCollaboratorSearchingType } from ".";
import Styles from "./index.module.scss";
import { useNewSetupPageAnalytics } from "..";

type TitleAgencyNameOrIdProps = {
  /** Hook result containing the state required to render this component */
  interaction: ReturnType<typeof useCollaboratorSearchingType>;
};

const MESSAGES = defineMessages({
  noResultFound: {
    id: "f0e86cdc-a2b6-4965-b8c8-32a11a917eca",
    defaultMessage: "No result found.",
  },
  addNewOrganization: {
    id: "b7af7cd4-a073-4fb9-8e90-3a3bffb019e1",
    defaultMessage: "+ Add new organization",
  },
  loading: {
    id: "d8349cad-2e5d-4434-a420-b378bbbc6a83",
    defaultMessage: "Loading...",
  },
  orgNameOrId: {
    id: "0fcdc337-8f53-4a09-9de9-05b8ec1f14b7",
    defaultMessage: "Organization name / ID",
  },
  resetBusinessName: {
    id: "59d512c5-6d7d-42c5-a979-5fa7715a3fe9",
    defaultMessage: "Reset business name",
  },
});

const optionRenderer = (option: TitleAgencyOption) => (
  <div className={Styles.optionRender}>
    <span>{option.label}</span>
    {option.value && <span className={Styles.organizationId}> ({option.value})</span>}
    <span className={Styles.address}>{formattedPropertyAddress(option.address)}</span>
  </div>
);

const valueRenderer = (option: TitleAgencyOption) => (
  <>
    <span className={Styles.valueRender}>{option.label}</span>
    {option.value && <span className={Styles.organizationId}> ({option.value})</span>}
  </>
);

export default function TitleAgencyNameOrIdInput({
  interaction: {
    setTitleAgency,
    setEligibleTitleUnderwriters,
    titleAgencyInput,
    titleAgencyAutocompleteService: {
      selectedTitleAgency,
      setSelectedTitleAgency,
      setAutocompleteInput,
      handleAutocompleteChange,
      handleTitleAgencyChange,
      autocompleteLoading,
      autocompleteOptions,
    },
    collaboratorEmail,
  },
}: TitleAgencyNameOrIdProps) {
  const intl = useIntl();
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const { track } = useNewSetupPageAnalytics();
  const [nonOnboardedTitleAgency, setNonOnboardedTitleAgency] = useState<boolean>(false);
  const [titleAgencyName, setTitleAgencyName] = titleAgencyInput;

  useEffect(() => {
    scrollAndFocusInput(selectContainerRef.current?.querySelector("input"));
  }, []);

  useEffect(() => {
    if (!selectedTitleAgency) {
      setTitleAgencyName("");
    }
  }, [selectedTitleAgency]);

  const newOrg = {
    name: titleAgencyName,
    id: "new",
    address: { line1: "", city: "", state: "" },
    isSetup: { eligibilityComplete: false },
  };
  const unformattedItems = [...(nonOnboardedTitleAgency ? [newOrg] : []), ...autocompleteOptions];
  const items = unformattedItems.map((option) => ({
    value: option.id,
    label: option.name,
    address: option.address,
  }));

  const addNewOrganization = () => {
    setTitleAgency({ name: titleAgencyName, id: "", isSetup: { eligibilityComplete: false } });
    setEligibleTitleUnderwriters([]);
    setSelectedTitleAgency(newOrg);
    setNonOnboardedTitleAgency(true);
    track("title-agent-add-new");
  };

  const resetBusinessName = () => {
    setTitleAgency(null);
    setEligibleTitleUnderwriters([]);
    handleTitleAgencyChange(null);
    setNonOnboardedTitleAgency(false);
    track("title-agent-reset-name");
  };

  const handleOptionChange = (option?: TitleAgencyOption | null) => {
    if (option?.label && option.value) {
      setTitleAgency({
        name: option.label,
        id: option.value,
        isSetup: { eligibilityComplete: false },
      });
      handleTitleAgencyChange(option.value);
      setTitleAgencyName(option.label);
      track("title-agent-manual-select");
    } else {
      setTitleAgency(null);
      handleTitleAgencyChange(null);
      setEligibleTitleUnderwriters([]);
      track("title-agent-cleared");
    }
    setNonOnboardedTitleAgency(false);
  };

  const handleInputChange = (titleAgencyName: string) => {
    /**
     *  The back-end only search for search term that is greater than or equal to 3 letters
     *  We should only make change if search term is greater than 2 letters, which is equivalent
     */
    if (titleAgencyName.length > 2) {
      setTitleAgencyName(titleAgencyName);
      handleAutocompleteChange(titleAgencyName);
    }
  };

  const handleNoResultsText = () => {
    /**
     * Only show if:
     * - the title agency name does not exist and currently there is no selected one OR
     * - the selected title agency name is different than the name on the input
     */
    const noTitleAgencyExists = !autocompleteOptions.length && !selectedTitleAgency;
    const differentTitleAgencyName = selectedTitleAgency?.name !== titleAgencyName;
    const showNoResults = noTitleAgencyExists || differentTitleAgencyName;

    return (
      showNoResults && (
        <>
          <div className={Styles.noResultFound}>{intl.formatMessage(MESSAGES.noResultFound)}</div>
          {collaboratorEmail && (
            <div className={Styles.addNewOrganization}>
              <Button
                variant="tertiary"
                buttonColor="action"
                automationId="add-new-organization"
                onClick={addNewOrganization}
              >
                {intl.formatMessage(MESSAGES.addNewOrganization)}
              </Button>
            </div>
          )}
        </>
      )
    );
  };

  return (
    <>
      <div ref={selectContainerRef} className={Styles.collaboratorOrgNameDetails}>
        <DeprecatedStyledSelectInput
          closeOnSelect={false}
          key="title-agency-name-or-id"
          data-automation-id="title-agency-name-or-id"
          name="titleAgencyNameOrId"
          useStyledInput
          placeholderAsLabel
          clearable={!nonOnboardedTitleAgency}
          searchable
          onChange={() => {}}
          items={items}
          onInputChange={handleInputChange}
          noResultsText={
            autocompleteLoading ? intl.formatMessage(MESSAGES.loading) : handleNoResultsText()
          }
          onClose={() => {
            setAutocompleteInput("");
            setTitleAgencyName(selectedTitleAgency?.name ?? "");
          }}
          optionRenderer={optionRenderer}
          valueRenderer={valueRenderer}
          onOptionChange={handleOptionChange}
          filterOptions={(options: TitleAgencyOption[]) => {
            // this is to offload responsibility for filtering from react-select to our BE.
            // Typically, we'd rely on ReactSelect's async module, but we need finer control
            // over the options list which Async does not provide.
            if (selectedTitleAgency) {
              return options.filter(({ value }) => value !== selectedTitleAgency.id);
            }
            return options;
          }}
          value={selectedTitleAgency?.id}
          placeholder={intl.formatMessage(MESSAGES.orgNameOrId)}
        />
      </div>

      {nonOnboardedTitleAgency && (
        <div className={Styles.resetBusinessName}>
          <Button
            variant="tertiary"
            buttonColor="action"
            automationId="reset-business-name"
            onClick={resetBusinessName}
          >
            {intl.formatMessage(MESSAGES.resetBusinessName)}
          </Button>
        </div>
      )}
    </>
  );
}
